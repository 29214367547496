import React from "react";
import { useCopy } from "utility/copyContext";

export const AboutSection = () => {
  const { home, header } = useCopy();
  const fmIncludesList = Object.values(home.about.FMIncludesList);
  var video = document.querySelector("video");
  video?.addEventListener("ended", function () {
    video.load();
  });
  return (
    <section className="global_section global_section_about" id={header.scrollableSections.about}>
      <div className="global_section_about_content">
        <div className="global_section_about_content_left">
          <div className="global_section_content">
            <h2
              className="global_section_title"
              dangerouslySetInnerHTML={{ __html: home.about.title }}></h2>
            <p dangerouslySetInnerHTML={{ __html: home.about.aboutIsFM }}></p>
          </div>
          <div className="global_section_content">
            <p dangerouslySetInnerHTML={{ __html: home.about.FMIncludes }}></p>
            <p dangerouslySetInnerHTML={{ __html: home.about.FMSuccessTitle }}></p>
            <ul className="list-unstyled fm_include_list">
              {fmIncludesList.map((data, idx) => {
                return <li key={idx} dangerouslySetInnerHTML={{ __html: data }}></li>;
              })}
            </ul>
          </div>
        </div>
        <div className="global_section_about_content_right">
          <div className="global_section_about_content_img">
            <img
              src="https://storage.googleapis.com/a.fintechmeetup.com/assets/img/FN_Intro.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
};

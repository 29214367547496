import React from "react";
import { useCopy } from "utility/copyContext";

export const IntroVideo = () => {
  const { home } = useCopy();
  return (
    <section className="global_section global_section_introVideo" id="introVideo">
      <div className="global_section_introVideo_wrapper">
        <video src={home.introVideo.video} loop muted autoPlay playsInline />
      </div>
    </section>
  );
};

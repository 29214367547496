import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function TermsOfAttendanceAndParticipation() {
  useWindowScrollTop();
  const { termsOfParticipation } = useCopy();
  return (
    <main className="main_termsOfParticipation">
      <div className="container">
        <h1
          className="static_pageTitle text-center"
          dangerouslySetInnerHTML={{ __html: termsOfParticipation.title }}
        />
        <div className="static_page_contentHolder">
          {termsOfParticipation.headerNote.map((data, idx) => (
            <p key={idx} dangerouslySetInnerHTML={{ __html: data }} />
          ))}
          <div className="static_page_participationCont">
            {Object.keys(termsOfParticipation.keypoints).map(keyPoint => (
              <div className="static_contentList_subSection" key={keyPoint}>
                <strong>{termsOfParticipation.keypoints[keyPoint].title}</strong>
                <ul className="list-unstyled">
                  {termsOfParticipation.keypoints[keyPoint].points.map(point => (
                    <li
                      key={point}
                      dangerouslySetInnerHTML={{
                        __html: point
                      }}></li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}

import React from "react";
import SpeakerCard from "./components/speakerCard";
import FullPageLoader from "components/FullPageLoader";
import useSpeakerService from "./hooks/speakerService";
import SpeakerTopSection from "./components/speakerTopSection";

export default function Speakers() {
  const speakerService = useSpeakerService();
  return (
    <main className="speakers_container">
      <FullPageLoader isLoading={speakerService.isLoading} />
      <div className="container">
        <SpeakersList speakerService={speakerService} />
      </div>
    </main>
  );
}

function SpeakersList({ speakerService }) {
  return (
    <section className="speakersLogoWrapper">
      <SpeakerTopSection
        mainTitle={speakerService.speakers.title}
        btnText={speakerService.speakers.applyToSpeak}
        viewAgenda={speakerService.speakers.viewAgenda}
        viewAgendaUrl={speakerService.speakers.viewAgendaUrl}
        speakerDescription={speakerService.speakers.description}
      />
      {Boolean(speakerService.speakers.enableSpeakers) && (
        <div className="speakers_wrapper">
          <div className="speakers_list">
            <ul className="list-inline">
              {speakerService.sortedSpeakersData.length > 0 && (
                <SpeakerCard dataObj={speakerService.sortedSpeakersData} />
              )}
            </ul>
          </div>
        </div>
      )}
    </section>
  );
}

import React, { useState } from "react";
import FM_LINKS from "routes";
import classNames from "classnames";
import queryString from "query-string";
import { CustomLink } from "components/Link";
import { Link as ScrollLink } from "react-scroll";
import { Link, useNavigate } from "react-router-dom";
import { useCopy } from "../../utility/copyContext";
import { ReactComponent as Logo } from "assets/img/FN_LOGO.svg";
import { SCROLL_DURATION, SCROLL_OFFSET } from "helpers/constants";
import { EmailIcon, InstagramIcon, LinkedinIcon, TwitterIcon, YouTubeIcon } from "assets/svg";
import Marquee from "react-fast-marquee";
import { isMobile } from "helpers/utils";

export default function Header({ setOpenWaitListForm }) {
  const { generalStr } = useCopy();
  const { header } = useCopy();
  const [showMenu, setShowMenu] = React.useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const headerRef = React.useRef();
  const menuRef = React.useRef();

  React.useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight;
    if (headerHeight) {
      document.body.style.paddingTop = `${headerHeight}px`;
      if (isMobile()) {
        menuRef.current.style.top = `${headerHeight}px`;
        menuRef.current.style.height = `calc(100% - ${headerHeight}px)`;
      }
    }
  }, []);

  return (
    <header className="header smooth" ref={headerRef}>
      <HeaderTop text={header.topBar } />
      {/* <div className="header_top">
        <Marquee loop={0} speed={80} pauseOnHover={true}>
          <p dangerouslySetInnerHTML={{ __html: header.topBar }} />
        </Marquee>
      </div> */}
      <div className="container">
        <div className="header_container">
          <div className="header_logo">
            <a href="/" title={generalStr.eventName}>
              <Logo />
            </a>
          </div>
          <div className="header_navLinks">
            <HamburgerButton
              setShowMenu={setShowMenu}
              showMenu={showMenu}
              isSubMenuOpen={isSubMenuOpen}
              setIsSubMenuOpen={setIsSubMenuOpen}
            />
            <div className="header_navLinks_top lgVisible">
              <SocialMediaLinks setOpenWaitListForm={setOpenWaitListForm} />
            </div>
            <div
              ref={menuRef}
              className={classNames("header_navLinks_bottom", {
                openNav: showMenu
              })}>
              <NavLinks
                setShowMenu={setShowMenu}
                setIsSubMenuOpen={setIsSubMenuOpen}
                isSubMenuOpen={isSubMenuOpen}
                setOpenWaitListForm={setOpenWaitListForm}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

function HeaderTop( {text} ) {
  if (text) {
    return <div className="header_top">
        <Marquee loop={0} speed={80} pauseOnHover={true}>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </Marquee>
      </div>;
  }
}

export const HamburgerButton = ({ setShowMenu, showMenu, setIsSubMenuOpen }) => {
  const menuClicked = () => {
    setShowMenu(showMenu => !showMenu);
    if (isMobile()) {
      setIsSubMenuOpen(false);
    }
  };
  return (
    <span
      className={classNames("showMenu xsVisible", { menuOpened: showMenu })}
      onClick={menuClicked}>
      <small className="a smooth"></small>
      <small className="b smooth"></small>
      <small className="c smooth"></small>
    </span>
  );
};

export const SocialMediaLinks = ({ setOpenWaitListForm }) => {
  const { header, generalStr } = useCopy();
  return (
    <ul className="list-inline socialMedia">
      <li>
        <CustomLink
          href="https://www.linkedin.com/company/fintechmeetup"
          target="_blank"
          title={header.linkedin}>
          <LinkedinIcon title={header.linkedin} />
        </CustomLink>
      </li>
      <li>
        <CustomLink href="https://x.com/Fintechmeetup" target="_blank" title={header.twitter}>
          <TwitterIcon title={header.twitter} />
        </CustomLink>
      </li>
      <li>
        <CustomLink
          href="https://www.youtube.com/@FintechMeetup"
          target="_blank"
          title={header.youtube}>
          <YouTubeIcon title={header.youtube} />
        </CustomLink>
      </li>
      <li>
        <CustomLink
          href="https://www.instagram.com/fintechmeetup"
          target="_blank"
          title={header.instagram}>
          <InstagramIcon title={header.instagram} />
        </CustomLink>
      </li>
      <li className="joinMailList">
        <span title={generalStr.mailingList} onClick={() => setOpenWaitListForm(true)}>
          <EmailIcon />
          <span>{generalStr.mailingList}</span>
        </span>
      </li>
    </ul>
  );
};

export function NavLinks({ setShowMenu, isSubMenuOpen, setIsSubMenuOpen, setOpenWaitListForm  }) {
  let navigate = useNavigate();
  const { header } = useCopy();
  const { spId: sponsorOrganizationId } = queryString.parse(window.location.search);

  // const toggleSubMenu = React.useCallback((e) => {
  //   if (isMobile()) {
  //     console.log('open_'+ e.id);
  //     setIsSubMenuOpen(prevIsSubMenuOpen => !prevIsSubMenuOpen);
  //   }
  // }, [setIsSubMenuOpen]);

  const toggleSubMenu = (e) => {
    if (isMobile()) {
      var id = e.currentTarget.id;
      console.log('open_'+ id);

      // close all the menus
      // var uls = document.querySelectorAll('.header_navLinks_subMenu');
      // console.log(uls);
      // for(const u of uls){
      //   u.classList.remove("subMenuOpen");
      // }

      var ul = document.querySelector('#'+id+'>ul');
      if(ul.classList.contains('subMenuOpen'))
      {
        ul.classList.remove('subMenuOpen');
      }else{
        ul.classList.add('subMenuOpen');
      }
    }
  };




  const closeMenu = React.useCallback(() => {
    if (isMobile()) {
      setIsSubMenuOpen(false);
    }
    setShowMenu(false);
  }, [setShowMenu, setIsSubMenuOpen]);

  const redirectToHome = e => {
    let pathName = window.location.pathname;
    let clickedEle = e.target.attributes[1].value;
    closeMenu();
    if (!sponsorOrganizationId && pathName !== "/") {
      navigate({ pathname: FM_LINKS.HOME, hash: "#" + clickedEle });
    } else if (sponsorOrganizationId) {
      window.open(FM_LINKS.HOME + "#" + clickedEle);
      window.history.pushState("", "", "");
    }
  };

  return (
    <React.Fragment>
      <nav className="header_navLinks_bottom_nav text-right">
        <ul className="list-inline">

        {/* ####### ABOUT MENU #######  */}
          <li className="hasSubMenu" id="menu-about" onClick={toggleSubMenu}>
            <span>ABOUT</span>
            <ul className={'list-unstyled header_navLinks_subMenu'}>
               <li>
                <ScrollLink
                  smooth={true}
                  title="THE FINTECH MEETUP DIFFERENCE "
                  offset={SCROLL_OFFSET}
                  onClick={redirectToHome}
                  duration={SCROLL_DURATION}
                  to={header.scrollableSections.about}
                  data-id={header.scrollableSections.about}>
                  THE FINTECH MEETUP DIFFERENCE 
                </ScrollLink>
              </li>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.FEEDBACK}
                  title="FEEDBACK">
                  FEEDBACK
                </Link>
              </li>
              <li>
                <span title="CONTACT" onClick={() => setOpenWaitListForm(true)}>CONTACT</span>
              </li>
            </ul>
          </li>


          {/* ####### AGENDA AND SPEAKERS MENU #######  */}
          <li className="hasSubMenu" id="menu-agenda" onClick={toggleSubMenu}>
            <span>AGENDA &amp; SPEAKERS</span>
            <ul className={'list-unstyled header_navLinks_subMenu'}>
              {/* <li>
                <ScrollLink
                  smooth={true}
                  title="AGENDA PREVIEW"
                  offset={SCROLL_OFFSET}
                  onClick={redirectToHome}
                  duration={SCROLL_DURATION}
                  to={header.scrollableSections.agendaSpeakers}
                  data-id={header.scrollableSections.agendaSpeakers}>
                  AGENDA PREVIEW
                </ScrollLink>
              </li> */}
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.AGENDA}
                  title={header.agenda}>
                  {header.agenda}
                </Link>
              </li> 
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.SPEAKERS}
                  title="SPEAKERS">
                  SPEAKERS
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.APPLY_TO_SPEAK_FORM}
                  title="APPLY TO SPEAK">
                  APPLY TO SPEAK
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.SPEAKER_LEAD_FORM}
                  title="Banks and Credit Unions">
                  Banks and Credit Unions
                </Link>
              </li>
            </ul>
          </li>

          {/* ####### SPONSOR MENU #######  */}
          <li className="hasSubMenu" id="menu-sponsor" onClick={toggleSubMenu}>
            <span>SPONSOR</span>
            <ul className={'list-unstyled header_navLinks_subMenu'}>
                <li>
                  <Link
                    target="_blank"
                    onClick={closeMenu}
                    to={FM_LINKS.SPONSORS}
                    title="SPONSORS">
                    SPONSORS
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    onClick={closeMenu}
                    to="/sponsor_download_form.html"
                    title="DOWNLOAD BROCHURE">
                    DOWNLOAD BROCHURE
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    onClick={closeMenu}
                    to="/sponsor_form.html "
                    title="APPLY TO SPONSOR">
                    APPLY TO SPONSOR
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    onClick={closeMenu}
                    to="https://9197169.fs1.hubspotusercontent-na1.net/hubfs/9197169/Fintech%20Meetup%20Floor%20Plan%20PDF%201.pdf"
                    title="FLOORPLAN">
                    FLOORPLAN
                  </Link>
                </li>
              
            </ul>
          </li>

          {/* ####### EXPERIENCE MENU #######  */}
          <li className="hasSubMenu" id="menu-experience" onClick={toggleSubMenu}>
            <span>EXPERIENCE</span>
            <ul className={'list-unstyled header_navLinks_subMenu'}>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.HOTEL}
                  title="HOTEL INFORMATION">
                  HOTEL INFORMATION
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  onClick={closeMenu}
                  to={FM_LINKS.CO_LOCATED_EVENTS}
                  title="CO-LOCATED EVENTS">
                  CO-LOCATED EVENTS
                </Link>
              </li>
            </ul>
          </li>

          {/* temporarily commented for Fintech25 */}
          {/* <li>
            <Link
              target="_blank"
              onClick={closeMenu}
              to={FM_LINKS.SCHEDULE}
              title={header.schedule}>
              {header.schedule}
            </Link>
          </li> */}
          

          {!sponsorOrganizationId && (
            <li>
              <Link
                target="_blank"
                onClick={closeMenu}
                to={FM_LINKS.TICKETS}
                className="get_ticket_btn"
                title={header.getTicket}>
                {header.getTicket}
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </React.Fragment>
  );
}

import React from "react";
import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function ExhibitorRulesAndRegulations() {
  useWindowScrollTop();
  const { exhibitorRulesAndRegulations } = useCopy();
  return (
    <React.Fragment>
      <main className="main_exhibitorRulesAndRegulations">
        <div className="container">
          <h1 className="static_pageTitle text-center">{exhibitorRulesAndRegulations.title}</h1>
          <div className="static_page_contentHolder">
            {exhibitorRulesAndRegulations.headerNote.map((data, idx) => (
              <p key={idx}>{data}</p>
            ))}
            <div className="static_page_privacyCont">
              <ul className="list-unstyled">
                {Object.keys(exhibitorRulesAndRegulations.keypoints).map((point, idx) => (
                  <li key={idx}>
                    <strong>{exhibitorRulesAndRegulations.keypoints[point].title}</strong>
                    {exhibitorRulesAndRegulations.keypoints[point].list.map((list, key) => {
                      return (
                        <React.Fragment key={key}>
                          {list.listContent && (
                            <p key={key} dangerouslySetInnerHTML={{ __html: list.listContent }} />
                          )}
                          {list.subList && (
                            <ul className="listy-unstyled">
                              {list.subList.map((item, key) => {
                                return <li key={key} dangerouslySetInnerHTML={{ __html: item }} />;
                              })}
                            </ul>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

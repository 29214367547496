import React from "react";
import { CloseIcon } from "assets/svg";
import { useCopy } from "utility/copyContext";



export default function ModalText(props) {
  const { applyToSpeakForm } = useCopy();
  var height = props.height ? props.height : "100vh";
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    //document.body.style.height = "100vh";

//applyToSpeakForm.popupTitle 
//applyToSpeakForm.popupContent
    document.body.style.height = height;
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, []);

  return (
    <div className="waitListFormModal">
      <div className="waitListFormModal_dailog formLoaded">
        <button className="waitListFormModal_close" onClick={props.onClose}>
          <CloseIcon />
        </button>
        <h3 dangerouslySetInnerHTML={{ __html: props.title }}></h3>
        <p>{props.specialText}</p>
        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
      </div>
    </div>
  );
}

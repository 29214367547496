import React from "react";
import { useCopy } from "utility/copyContext";

export const WhySection = () => {
  const { home } = useCopy();
  const reasonsArr = Object.values(home.why.reasons);
  return (
    <section className="global_section global_section_why" id="why">
      <div className="container">
        <h3
          className="global_section_title"
          dangerouslySetInnerHTML={{ __html: home.why.title }}></h3>
        <div className="global_section_content">
          <p className="text-center" dangerouslySetInnerHTML={{ __html: home.why.tagLine }}></p>
        </div>
        <div className="reasons_list">
          <ul className="list-inline">
            {reasonsArr.map((data, idx) => {
              return (
                <li key={idx}>
                  <div className="reasons_list_box">
                    <div className="reasons_list_box_img">
                      <span>
                        <img src={data.imgPath} alt="" />
                      </span>
                    </div>
                    <b
                      className="reasons_list_box_text"
                      dangerouslySetInnerHTML={{ __html: data.reasonText }}></b>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

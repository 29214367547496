import React from "react";
import { useQuery } from "./useQuery";
import { useCopy } from "utility/copyContext";
import { Base64Util } from "helpers/base64Util";
import {
  FORM_ID,
  COUPON_CODE,
  SPONSORS_ORG_ID,
  REGISTRATION_ROUTE_INITIATE_REG
} from "helpers/constants";

export default function useGetTicketUrl(ticket) {
  const query = useQuery();
  const { generalStr } = useCopy();
  return React.useMemo(() => {
    var ticketId = ticket.id;
    var isBulk = false;
    //special for bulk only
    if (ticketId === '742243d3-e488-4928-a2b1-88f2cb7e0fb5'){
      isBulk = true;
      ticketId = '102f3e12-c6bf-45ab-b69a-d8ed55a30ec0'; //general attendees
    }
    const encodedTicketId = Base64Util.encode(ticketId);
    var baseTicketUrl = `https://${generalStr.registrationBaseUrl}${REGISTRATION_ROUTE_INITIATE_REG}?${FORM_ID}=${encodedTicketId}`;

    //special for bulk only
    if (isBulk){
      baseTicketUrl += '&rft=QlVMSw==';
    }
    
    const couponCode = query.get(COUPON_CODE);
    const sponsorOrgId = query.get(SPONSORS_ORG_ID);

    if (couponCode) {
      return `${baseTicketUrl}&${COUPON_CODE}=${couponCode}`;
    }
    if (sponsorOrgId) {
      return `${baseTicketUrl}&${SPONSORS_ORG_ID}=${sponsorOrgId}`;
    }
    return baseTicketUrl;
  }, [generalStr.registrationBaseUrl, query, ticket.id]);
}

import React from "react";
import { BrokenImgIcon } from "assets/svg";

export default function SponsorImage({ imgData }) {
  return (
    <React.Fragment>
      {imgData.logoLocation ? (
        <img alt={imgData.organizationName} src={imgData.logoLocation} />
      ) : (
        <BrokenImgIcon />
      )}
    </React.Fragment>
  );
}

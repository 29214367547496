import React from "react";
import SponsorCard from "./components/sponsorCard";
import FullPageLoader from "components/FullPageLoader";
import useSponsorService from "./hooks/sponsorService";
import SponsorTopSection from "./components/sponsorTopSection";

export default function Sponsors() {
  const sponsorService = useSponsorService();
  return (
    <main>
      <FullPageLoader isLoading={sponsorService.isLoading} />
      <div className="container">
        <section className="sponsorsLogoWrapper">
          <SponsorTopSection sponsorService={sponsorService} />

          {sponsorService.sponsors.enableSponsorList && sponsorService.sponsorData?.length > 0 && (
            <React.Fragment>
              {sponsorService.goldSponsors.length > 0 && (
                <SponsorCard
                  className="isGoldSponsor"
                  dataObj={sponsorService.goldSponsors}
                  cardTitle={sponsorService.sponsors.goldSponsor}
                />
              )}
              {sponsorService.silverSponsors.length > 0 && (
                <SponsorCard
                  className="isSilverSponsor"
                  dataObj={sponsorService.silverSponsors}
                  cardTitle={sponsorService.sponsors.silverSponsor}
                />
              )}
              {sponsorService.bronzeSponsors.length > 0 && (
                <SponsorCard
                  className="isBronzeSponsor"
                  dataObj={sponsorService.bronzeSponsors}
                  cardTitle={sponsorService.sponsors.bronzeSponsor}
                />
              )}
              {sponsorService.mediaSponsors.length > 0 && (
                <SponsorCard
                  className="isMediaSponsor"
                  dataObj={sponsorService.mediaSponsors}
                  cardTitle={sponsorService.sponsors.mediaSponsors}
                />
              )}
            </React.Fragment>
          )}
        </section>
      </div>
    </main>
  );
}

import React from "react";
import { SponsorTypes } from "helpers/enums";
import { useCopy } from "utility/copyContext";
import { getSponsorsData } from "datasource/api";
import { sortBy } from "lodash";

export default function useSponsorService() {
  const { generalStr, sponsors } = useCopy();
  const [isLoading, setIsLoading] = React.useState(false);
  const [sponsorData, setSponsorData] = React.useState([]);

  const goldSponsors = sortBy(
    sponsorData?.filter(sponsor => sponsor?.sponsorshipLevel.priority === SponsorTypes.GOLD),
    ({ organizationName }) => organizationName.toLowerCase()
  );
  const silverSponsors = sortBy(
    sponsorData?.filter(sponsor => sponsor?.sponsorshipLevel.priority === SponsorTypes.SILVER),
    ({ organizationName }) => organizationName.toLowerCase()
  );

  const bronzeSponsors = sortBy(
    sponsorData?.filter(sponsor => sponsor?.sponsorshipLevel.priority === SponsorTypes.BRONZE),
    ({ organizationName }) => organizationName.toLowerCase()
  );

  const mediaSponsors = sortBy(
    sponsorData?.filter(sponsor => sponsor?.sponsorshipLevel.priority === SponsorTypes.MEDIA),
    ({ organizationName }) => organizationName.toLowerCase()
  );

  React.useEffect(() => {
    setIsLoading(true);
    getSponsorsData(generalStr.getSponsorsApi, generalStr.eventId)
      .then(res => {
        setSponsorData(res.data);
      })
      .catch(err => {
        setSponsorData(false);
      })
      .finally(res => {
        setIsLoading(false);
      });
  }, [generalStr.eventId, generalStr.getSponsorsApi]);

  return {
    sponsors,
    isLoading,
    sponsorData,
    goldSponsors,
    setIsLoading,
    setSponsorData,
    silverSponsors,
    bronzeSponsors,
    mediaSponsors
  };
}

import React from "react";
import { isMobile } from "helpers/utils";
import { useCopy } from "utility/copyContext";
import { ImageTitleContent } from "./components/imageTitleContent";
import { TitleContentListing } from "./components/titleContentListing";
import { ImageOrVideoContent } from "./components/imageorVideoContent";

export const Feedback = () => {
  const { feedBack } = useCopy();
  const isMobileDevice = isMobile();
  return (
    <div className="feedbackGradient">
      <h1
        className="global_section_title global_section_feedback_heading"
        dangerouslySetInnerHTML={{ __html: feedBack.title }}></h1>
      <ImageTitleContent
        className="global_section_futureOfEvents"
        contentPlacement={"bottom"}
        imgSrc={feedBack.futureOfEvents?.futureOfEventsImg}
        description={feedBack.futureOfEvents?.futureOfEventsContent}
      />
      <TitleContentListing
        className="global_section_showOfYear"
        title={feedBack.showOfYear.showOfYearTitle}
        subTitle={{
          top: feedBack.showOfYear.showOfYearSubTitle?.top,
          bottom: feedBack.showOfYear.showOfYearSubTitle?.bottom
        }}
        list={feedBack.showOfYear.showOfYearListing}
      />
      <ImageOrVideoContent
        className="smallCloud flipCloud"
        overlayContent={feedBack.newAndCoolEvents.overlayContent}
      />
      <ImageTitleContent
        className="global_section_fintechExperinced"
        contentPlacement={"bottom"}
        imgSrc={feedBack.fintechExperinced?.fintechExperincedImg}
        title={feedBack.fintechExperinced?.fintechExperincedTitle}
        description={feedBack.fintechExperinced?.fintechExperincedContent}
      />
      <ImageOrVideoContent
        className="bigCloud"
        imgSrc={feedBack.futureOfIndustry.imgSrc}
        overlayContent={feedBack.futureOfIndustry.overlayContent}
      />
      <TitleContentListing
        className="global_section_scaleAndImpact"
        title={feedBack.scaleAndImpact.scaleAndImpactTitle}
        subTitle={{
          top: feedBack.scaleAndImpact.scaleAndImpactSubTitle?.top
        }}
        list={feedBack.scaleAndImpact.scaleAndImpactListing}
        listHasImages
      />
      <ImageOrVideoContent
        className="smallCloud flipCloud"
        videoSrc={feedBack.videoSection.videoSrc}
        overlayContent={feedBack.videoSection.overlayContent}
      />
      <ImageOrVideoContent
        className="smallCloud"
        imgSrc={feedBack.gridSection.imgSrc}
        overlayContent={feedBack.gridSection.overlayContent}
      />
      <ImageTitleContent
        className="global_section_fintechReviews"
        contentPlacement={"top"}
        imgSrc={
          feedBack.fintechReviews?.[
            isMobileDevice ? "fintechReviewsImgMobile" : "fintechReviewsImgDesktop"
          ]
        }
        title={feedBack.fintechReviews?.fintechReviewsTitle}
        description={feedBack.fintechReviews?.fintechReviewsContent}
      />
      <ImageOrVideoContent
        className="bigCloud flipCloud"
        overlayContent={feedBack.saveTimeMoney.overlayContent}
      />
      <ImageTitleContent
        className="global_section_testimonials"
        contentPlacement={"top"}
        imgSrc={
          feedBack.testimonials?.[
            isMobileDevice ? "testimonialsImgMobile" : "testimonialsImgDesktop"
          ]
        }
        title={feedBack.testimonials?.testimonialsTitle}
      />
      <TitleContentListing
        title={feedBack.futureFintechEvents.futureFintechEventsTitle}
        subTitle={{
          top: feedBack.futureFintechEvents.futureFintechEventsSubTitle?.top,
          bottom: feedBack.futureFintechEvents.futureFintechEventsSubTitle?.bottom
        }}
        list={feedBack.futureFintechEvents.futureFintechEventsListing}
      />
      <ImageOrVideoContent
        className="bigCloud"
        overlayContent={feedBack.nextMeetup.overlayContent}
        content={feedBack.nextMeetup.content}
      />
    </div>
  );
};

import React from "react";
import { useCopy } from "utility/copyContext";

export const WelcomeFintech = () =>{
const { home } = useCopy();

return (
        <section className="global_section global_section_welcome" id="welcome">
            <div className="global_section_welcome_toptext">
                <div className="container">
                        {/* <div className="col"><h3>Perfect Timing:</h3><p>Fintech Meetup happens once every year, kicking off the year in Q1. As the first major fintech event of the year, it sets the tone for a year of innovation and growth. Start the year with the connections and insights that will fuel your success in the new era of fintech.</p></div>
                        <div className="col"><h3>Unmatched Meetings:</h3><p>Fintech Meetup is the largest and most productive event for networking in the industry. Hosting the industry’s biggest and most valuable meetings program, our groundbreaking technology powers over 60,000 scheduled meetings. Connect with the right people and unlock real business opportunities.</p></div>
                        <div className="col"><h3>Thriving Community:</h3><p>The fintech community loves Fintech Meetup. Join a vibrant community of fintech experts and thought leaders from around the world. Fintech Meetup continues to bring together the brightest minds to collaborate, learn, and shape the future of fintech.</p></div> */}

                        <div className="col" dangerouslySetInnerHTML={{ __html: home.topText.col1 }}></div>
                        <div className="col" dangerouslySetInnerHTML={{ __html: home.topText.col2 }}></div>
                        <div className="col" dangerouslySetInnerHTML={{ __html: home.topText.col3 }}></div>
                </div>
            </div>
            <div className="global_section_welcome_img">
                <div className="container">
                    {/* <div className="global_section_welcome_img_top">
                    <picture>
                        <source
                        media="(min-width: 768px)"
                        srcSet={home.coverImg.coverImgDesktop}></source>
                        <source
                        media="(min-width: 320px)"
                        srcSet={home.coverImg.coverImgMobile}></source>
                        <img src={home.coverImg.coverImgDesktop} alt="img" />
                    </picture>
                    </div> */}
                    <div className="global_section_welcome_img_bottom">
                        <picture>
                            <source
                            media="(min-width: 768px)"
                            srcSet={home.eventAchiveImg.eventAchiveImgDesktop}></source>
                            <source
                            media="(min-width: 320px)"
                            srcSet={home.eventAchiveImg.eventAchiveImgMobile}></source>
                            <img src={home.eventAchiveImg.eventAchiveImgDesktop} alt="img" />
                        </picture>
                    </div>
                </div>
            </div>

            <div className="global_section_loveFintech">
                <div className="container">
                    <h2
                        className="global_section_title"
                        dangerouslySetInnerHTML={{ __html: home.welcomeSection.title }}
                    />
                    <div className="global_section_loveFintech_img">
                        <picture>
                            <source
                            media="(min-width: 768px)"
                            srcSet={home.loveFintechImg.loveFintechImgDesktop}></source>
                            <source
                            media="(min-width: 320px)"
                            srcSet={home.loveFintechImg.loveFintechImgMobile}></source>
                            <img src={home.loveFintechImg.loveFintechImgDesktop} alt="img" />
                        </picture>
                    </div>
                </div>
            </div>
        </section>
    )
}
import { Link } from "react-router-dom";

import FM_LINKS from "routes";
import { useCopy } from "utility/copyContext";
import { ScheduleListIcon } from "assets/svg";

export default function CoLocatedEvents() {
  const { coLocatedEvents } = useCopy();

  return (
    <main className="schedule_wrapper">
      <section className="global_section global_section_schedule coLocatedEvents">
        <div className="container">
          <div className="global_section_schedule_content">
            <div className="global_section_schedule_content_left">
              <div className="global_section_title_wrapper">
                <h1
                  className="global_section_title text-left"
                  dangerouslySetInnerHTML={{ __html: coLocatedEvents?.title }}
                />
                <img src={coLocatedEvents?.titleImg} alt="img" />
              </div>
              <div className="global_section_content">
                {coLocatedEvents?.content?.map((item, index) => (
                  <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
                <ul className="list-unstyled">
                  {coLocatedEvents?.list.map((item, idx) => {
                    return (
                      <li key={idx}>
                        <ScheduleListIcon />
                        <span>{item}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="global_section_schedule_content_right">
              <img alt="Co-Located Event" src={coLocatedEvents?.eventImg} />
            </div>
            <div className="global_section_content global_section_schedule_content_fullWidth">
              {coLocatedEvents?.sectionContent?.map((item, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
              ))}
              <p>
                <span className="link_wrapper">
                  <Link
                    to={FM_LINKS.CO_LOCATED_EVENTS_FORM}
                    title={coLocatedEvents?.applyToEventButton}>
                    <span>{coLocatedEvents?.applyToEventButton}</span>
                  </Link>
                  &nbsp;
                  {coLocatedEvents?.applyToEventInfo}
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

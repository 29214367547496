const FM_LINKS = {
  HOME: "/",
  COOKIES: "/cookies",
  AGENDA: "/agenda.html",
  FEEDBACK: "/feedback.html",
  HOTEL: "/hotel.html",
  TICKETS: "/tickets.html",
  SPONSORS: "/sponsors.html",
  SCHEDULE: "/schedule.html",
  SPEAKERS: "/speakers.html",
  SPEAKER_LEAD_FORM: "/speaker-lead-form.html",
  // PRIVACY_POLICY: "/privacy.html",
  TERMS_OF_USE: "/terms-of-use.html",
  SPONSORS_FORM: "/sponsor_form.html",
  SPONSORS_DOWNLOAD_FORM: "/sponsor_download_form.html",
  CO_LOCATED_EVENTS: "/co-located-events.html",
  APPLY_TO_SPEAK_FORM: "/apply_to_speak_form.html",
  TERMS_AND_CONDITIONS: "/terms-and-conditions.html",
  CO_LOCATED_EVENTS_FORM: "/co-located-events-form.html",
  TERMS_OF_PARTICIPATION: "/terms-of-participation.html",
  HOSTED_TERMS_AND_CONDITIONS: "/hosted-terms-and-conditions.html",
  EXHIBITOR_RULES_AND_REGULATIONS: "/exhibitor-rules-and-regulations.html"
};
export default FM_LINKS;

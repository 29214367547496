import { useCopy } from "utility/copyContext";
//import { ENVIRONMENT_ASSETS_URL } from "helpers/constants";

export default function Hotel() {
  const { hotel } = useCopy();
  //const headerUrl = {ENVIRONMENT_ASSETS_URL} + "/assets/img/hotel-venetian-bg-header.png";

  return (
    <main className="hotel_content_wrapper">
      {/* <section className="hotel_content_img">
        <img
          alt="The Venetian Resort"
          src={headerUrl}
        />
      </section> */}
      <div className="container">
        <div className="hotel_content">
          <h1 dangerouslySetInnerHTML={{ __html: hotel?.title }} />
          {hotel?.content?.map((txt, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: txt }} />;
          })}
          <a
            target="_blank"
            rel="noreferrer"
            className="global_btn hotel_content_btn"
            href="https://book.passkey.com/gt/220208634?gtid=ded8343aada48afd9ceba1ef55f699f0"
            title={hotel?.bookYourRoom}>
            {hotel?.bookYourRoom}
          </a>
        </div>
        <div className="hotel_content">
          <h2 dangerouslySetInnerHTML={{ __html: hotel?.flight?.title }} />
          {hotel?.flight?.content?.map((txt, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: txt }} />;
          })}
        </div>
      </div>
    </main>
  );
}

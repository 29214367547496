import React from "react";
import classNames from "classnames";
import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

import ModalText from "components/ModalText";

export default function Agenda() {
  useWindowScrollTop();
  const { agenda, agenda_day_1, agenda_day_2, agenda_day_3, agenda_day_4 } = useCopy();
  var aryAgendaDays = [];
  aryAgendaDays.push(agenda_day_1, agenda_day_2, agenda_day_3, agenda_day_4);

 

  return (
    <main>
      <div className="container">
        <h1 className="static_pageTitle text-center" dangerouslySetInnerHTML={{ __html: agenda.title }} />
        

        <div className="static_page_contentHolder"> 
          {agenda.description.map((desc, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: desc }} />;
          })}
        </div>
      </div>
      <div className="container">
        <div class="agenda-content">
          {aryAgendaDays?.map((day, idx) => { return GetDayHtml(day, idx); })}
        </div>
      </div>
    </main>
  );
}

function GetDayHtml(day, idx){
  return(
    <div class="day">
      <h2 className="day-title subsection-title bright" dangerouslySetInnerHTML={{ __html: day.date }} />
      <div class="sessions">
            {day?.sessions?.map((s, idx) => {
              return GetSessionHtml(s, idx);
            })}
      </div>
    </div>
  )
};

function GetSessionHtml( session, idx ) {
  // if(session.instruction === "all"){
  //   return (
  //     AllTracksSessionHtml(session, idx))
  //   }else{
      return(SplitTracksSession(session, idx))
    // }
};

// function AllTracksSessionHtml( session, idx ) {
//   return (
//     <div className={classNames("row session", session.tracks[0].style)}>
//           <div class="col-12 col-md-3 session-time">
//             <p dangerouslySetInnerHTML={{ __html: session.start_time + " - " + session.end_time }}></p>
//           </div>
//           <div class="col-12 col-md-9 session-body">
//             <ContentItem content={session.tracks[0].heading} />
//           </div>
//     </div>
//   )
// };




function SplitTracksSession( session, idx ) {
  return (
    <div className={classNames("row session track-session", session.style)}>
        
          <div class="col-12 col-md-3 session-time">
            <p>{session.start_time} - {session.end_time} PT</p>
          </div>
          <div class="col-12 col-md-9 session-body">
            <div class="row tracks">
              {session?.tracks?.map((t, idx) => { return GetTrackHtml(t, idx); })}
            </div>
          </div>
    </div>
  );
}

function GetTrackHtml( track, idx ) {
  if(track.location === "all"){
    track.location = "";
  }

  return (
    <div className={classNames("col-md-6 col-xl track", track.style)}>
      <div class="track-header">
          <h4>{track.name}</h4>
          <h5>{track.location}</h5>
          <h3>{track.heading}</h3>
      </div>
      
      <div class="track-content">
        <ContentItem content={track.subheading} cls="track-subheading" />
        <ContentItem content={track.subsubheading} cls="track-subsubheading" />
        <ContentItem content={track.description} cls="track-description" ttl={track.subheading} trackRowType="desc" />
        <ContentItem content={track.speakers} cls="track-speakers" trackRowType="speakers" /> 

        <ContentItem content={track.subheading2} cls="track-subheading" />
        <ContentItem content={track.subsubheading2} cls="track-subsubheading" />
        <ContentItem content={track.description2} cls="track-description" ttl={track.subheading2} trackRowType="desc"  />
        <ContentItem content={track.speakers2} cls="track-speakers" trackRowType="speakers" /> 

        <ContentItem content={track.subheading3} cls="track-subheading" />
        <ContentItem content={track.subsubheading3} cls="track-subsubheading" />
        <ContentItem content={track.description3} cls="track-description" ttl={track.subheading3} trackRowType="desc"  />
        <ContentItem content={track.speakers3} cls="track-speakers" trackRowType="speakers" /> 

        <ContentItem content={track.subheading4} cls="track-subheading" />
        <ContentItem content={track.subsubheading4} cls="track-subsubheading" />
        <ContentItem content={track.description4} cls="track-description"  ttl={track.subheading4} trackRowType="desc"  />
        <ContentItem content={track.speakers4} cls="track-speakers" trackRowType="speakers" /> 
      </div>
    </div>
  )
};

function ContentItem(props){
  const [openModalText, setOpenModalText] = React.useState(false);

  var content = props.content;
  if(content !== undefined){
    if(content.includes("TBC")){
      if(props.trackRowType==="speakers"){
        content = "More speakers to be announced soon";
      }
      else if(props.trackRowType==="desc"){
        content = "More info to be announced soon";
      }
      else{
        content = "";
      }
    }
    if(props.ttl !== undefined) // only add title with a description
    {
      return(
        <div class="description-button"><span onClick={() => setOpenModalText(true)}>View Description</span>
        {
          openModalText && 
          <ModalText 
            onClose={() => setOpenModalText(false)} 
            height="100px" 
            title={props.ttl} 
            content={content}  
          />
        }
        </div>
      ) 

    }
    else{
      return(
        <p className={props.cls} dangerouslySetInnerHTML={{ __html: content }}/>
      ) 
    }
  }
}
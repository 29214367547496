import React from "react";
import { CloseIcon } from "assets/svg";
import { HubSpotFormName } from "helpers/enums";
import HubSpotForm from "components/HubSpotForm";
import FullPageLoader from "components/FullPageLoader";

export default function WaitListForm(props) {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    return () => {
      document.body.style.overflow = "";
      document.body.style.height = "";
    };
  }, []);

  return (
    <div className="waitListFormModal">
      <div className="waitListFormModal_dailog">
        <button className="waitListFormModal_close" onClick={props.onClose}>
          <CloseIcon />
        </button>
        <HubSpotForm
          formName={HubSpotFormName.GET_TICKET}
          onLoading={<FullPageLoader isLoading={true} />}
          onReady={() =>
            document
              .getElementsByClassName("waitListFormModal_dailog")[0]
              .classList.add("formLoaded")
          }
        />
      </div>
    </div>
  );
}

import React from "react";
import classNames from "classnames";
import { useCopy } from "utility/copyContext";
import HubSpotForm from "components/HubSpotForm";
import { HubSpotFormName } from "helpers/enums";
import FullPageLoader from "components/FullPageLoader";
import { isIEBrowser, setTextAreaMaxLengths } from "helpers/utils";

export default function SpeakerLeadForm() {
  // React.useEffect(() => {
  //   sponsorsPageCriteoEvent();
  // }, []);

  window.jQuery = // required for hubspot, as hubspot `onFormSubmitted` requries jquery
    window.jQuery ||
    (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
      serializeArray: () => {}
    }));

  const { speakerLeadForm } = useCopy();
  const [showTopSection, setShowTopSection] = React.useState(true);

  return (
    <section className={classNames("sponsorForm", { "sponsorForm--onIe": isIEBrowser() })}>
      {showTopSection && (
        <div className="sponsorForm_topSection">
          <h1 dangerouslySetInnerHTML={{ __html: speakerLeadForm.title }}></h1>
          {speakerLeadForm.content && (
            <div className="sponsorForm_topSection_content">
              <p dangerouslySetInnerHTML={{ __html: speakerLeadForm.content }}></p>
            </div>
          )}
        </div>
      )}


        <HubSpotForm
          formName={HubSpotFormName.SPEAKER_LEAD_FORM}
          onLoading={<FullPageLoader isLoading={true} />}
          onReady={() => setTextAreaMaxLengths()}
          onFormSubmitted={() => setShowTopSection(false)}
        />
      
      
    </section>
  );
}

import React from "react";

export const CustomLink = React.memo(({ target, href, url, label, title, ...props }) => {
  return (
    <a
      href={href || url}
      title={title || ""}
      target={target || ""}
      rel="noopener noreferrer"
      style={{ backgroundColor: props.color }}>
      {label || url || props.children}
    </a>
  );
});

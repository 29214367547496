import React from "react";
import { useCopy } from "utility/copyContext";
import { getResolvedString } from "helpers/utils";

export default function TicketErrorMessage() {
  const { generalStr, tickets } = useCopy();
  return (
    <div className="ticketErrorMessage">
      <p
        dangerouslySetInnerHTML={{
          __html: getResolvedString(tickets.errorMsg, {
            contactEmail: `<a href="mailto:${generalStr.contactEmail}" title="Email Us">${generalStr.contactEmail}</a>`
          })
        }}></p>
    </div>
  );
}

import React from "react";
import { useCopy } from "utility/copyContext";

export default function Cookies() {
  const { cookies } = useCopy();
  React.useEffect(() => {
    window.OneTrust?.initializeCookiePolicyHtml();
  }, []);

  return (
    <main>
      <div className="container">
        <h1 className="cookies_pageTitle text-center" style={{ marginBottom: "16px" }}>
          {cookies.title}
        </h1>
        <div className="cookies_page_contentHolder">
          {cookies.description.map((desc, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: desc }} />;
          })}
        </div>
        <div id="ot-sdk-cookie-policy"></div>
      </div>
    </main>
  );
}

import React from "react";
import FM_LINKS from "routes";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useStickActionItemsTop from "hooks/useStickActionItemsTop";

function ViewFloorPlanButton({ showButton, ss }) {
  if (showButton) {
    return (
      <a
      target="_blank"
      rel="noreferrer"
      href={ss.sponsors.floorPlan}
      title={ss.sponsors.viewFloorPlan}
      className="global_btn sponsors_viewFloorPlan">
      {ss.sponsors.viewFloorPlan}
    </a>
    );
  }
}

export default function SponsorTopSection({ flipContent, sponsorService }) {
  const ref = React.useRef();
  const [sticky, setSticky] = React.useState(false);


  useStickActionItemsTop(ref, setSticky);

  return (
    <div className="sponsors_topSection">
      <h1 className="pageTitle text-center">{sponsorService.sponsors.title}</h1>
      <div
        className={classNames("sponsors_topSection_content", {
          flipSponsorContent: flipContent
        })}>
        <div className="sponsors_topSection_content_left">
          <p dangerouslySetInnerHTML={{ __html: sponsorService.sponsors.description }}></p>
        </div>
        <div
          ref={ref}
          className={classNames("sponsors_topSection_content_right", { isSticky: sticky })}>
          <Link to={FM_LINKS.SPONSORS_FORM} title={sponsorService.sponsors.applyToSponsor}>
            <button className="global_btn">{sponsorService.sponsors.applyToSponsor}</button>
          </Link>
          
          <ViewFloorPlanButton showButton={sponsorService.sponsors.showViewFloorPlanButton} ss={sponsorService}></ViewFloorPlanButton>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import FM_LINKS from "routes";
import classNames from "classnames";
import SpeakerImage from "./speakerImage";
import { useNavigate } from "react-router-dom";

export default function SpeakerCard({ dataObj }) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      {dataObj?.map((speakerData, key) => (
        <React.Fragment key={key}>
          {speakerData.speakerName && (
            <li key={key}>
              <div
                onClick={() => navigate(`${FM_LINKS.SPEAKERS}/${speakerData.speakerRoute}`)}
                className={classNames("speakers_card", {
                  hasBrokenImg: !speakerData.speakerImage
                })}>
                <span className="speakers_card_img">
                  <SpeakerImage imgData={speakerData} />
                </span>
                <div className="speakers_card_details">
                  <strong className="speakers_card_speakerName">{speakerData.speakerName}</strong>
                  {speakerData.speakerTitle && (
                    <span className="speakers_card_speakerType">{speakerData.speakerTitle}</span>
                  )}
                  {speakerData.speakerCompany && (
                    <p className="speakers_card_speakerCompany">{speakerData.speakerCompany}</p>
                  )}
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

import React from "react";
import classNames from "classnames";
import TicketList from "./ticketList";
import queryString from "query-string";
import NoTicketsFound from "./noTicketsFound";
import { useCopy } from "utility/copyContext";
import { Base64Util } from "helpers/base64Util";
import { getRegFormsList } from "datasource/api";
import FullPageLoader from "components/FullPageLoader";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function CommunitySponsorTickets() {
  useWindowScrollTop();
  const { generalStr, tickets } = useCopy();
  const [isLoading, setIsLoading] = React.useState("");
  const { spId: sponsorOrganizationId } = queryString.parse(window.location.search);
  const [sponsorOrganizationTicketData, setSponsorOrganizationTicketData] = React.useState([]);

  React.useEffect(() => {
    if (generalStr.regClosed) return;
    setIsLoading(true);
    getRegFormsList(
      generalStr.getRegistrationFormsApi,
      generalStr.registrationId,
      Base64Util.decode(sponsorOrganizationId)
    )
      .then(res => {
        setSponsorOrganizationTicketData(res.data);
      })
      .catch(err => {
        setSponsorOrganizationTicketData(false);
      })
      .finally(res => {
        setIsLoading(false);
      });
  }, [
    generalStr.getRegistrationFormsApi,
    generalStr.regClosed,
    generalStr.registrationId,
    sponsorOrganizationId
  ]);

  return (
    <section
      className={classNames(
        "global_section global_section_tickets global_section_tickets--dynamicSponsorTickets",
        { hasOrgLogo: sponsorOrganizationTicketData.logo }
      )}
      id="dynamicSponsorTickets">
      <FullPageLoader isLoading={isLoading} />
      <div className="container">
        <h3 className="global_section_title" dangerouslySetInnerHTML={{ __html: tickets.title }} />
        {(sponsorOrganizationTicketData.logo ||
          sponsorOrganizationTicketData.description ||
          sponsorOrganizationTicketData.sponsorOrganizationName) && (
          <div className="global_section_tickets_content">
            <div className="global_section_tickets_content_wrapper">
              <strong>{sponsorOrganizationTicketData.sponsorOrganizationName}</strong>
              <p
                dangerouslySetInnerHTML={{
                  __html: sponsorOrganizationTicketData.description
                }}></p>
            </div>
            <div
              className={classNames("global_section_tickets_image", {
                noSponsorOrgLogo: !sponsorOrganizationTicketData.logo
              })}>
              {sponsorOrganizationTicketData.logo ? (
                <img
                  src={sponsorOrganizationTicketData.logo}
                  alt={sponsorOrganizationTicketData.sponsorOrganizationName}
                />
              ) : (
                <img src={tickets.defaultContentImg} alt="ticket" />
              )}
            </div>
          </div>
        )}
      </div>
      {sponsorOrganizationTicketData?.registrationForms?.length === 0 && (
        <div className="container">
          <NoTicketsFound noTicketsForCS={tickets.noTicketsForCS} />
        </div>
      )}
      {sponsorOrganizationTicketData?.registrationForms?.length > 0 && (
        <TicketList ticketData={sponsorOrganizationTicketData.registrationForms} />
      )}
    </section>
  );
}

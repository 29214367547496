import React from "react";
import classNames from "classnames";

import { useCopy } from "utility/copyContext";
import { HubSpotFormName } from "helpers/enums";
import HubSpotForm from "components/HubSpotForm";
import FullPageLoader from "components/FullPageLoader";
import { FORM_TEXTAREA_VALIDATIONS } from "./constants";
import { isIEBrowser, setTextAreaMaxLengths } from "helpers/utils";

export default function CoLocatedEventsForm() {
  window.jQuery = // jugaad for hubspot, as hubspot `onFormSubmitted` requries jquery
    window.jQuery ||
    (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
      serializeArray: () => {}
    }));

  const { coLocatedEventForm, generalStr } = useCopy();
  const [showTopSection, setShowTopSection] = React.useState(true);

  return (
    <section className={classNames("sponsorForm", { "sponsorForm--onIe": isIEBrowser() })}>
      {showTopSection && (
        <div className="sponsorForm_topSection">
          <h1 dangerouslySetInnerHTML={{ __html: coLocatedEventForm.title }}></h1>
          {coLocatedEventForm.content && (
            <div className="sponsorForm_topSection_content">
              <p dangerouslySetInnerHTML={{ __html: coLocatedEventForm.content }}></p>
            </div>
          )}
        </div>
      )}
      {generalStr.isCoLocatedEventsFormVisible && (
        <HubSpotForm
          formName={HubSpotFormName.CO_LOCATED_EVENTS}
          onLoading={<FullPageLoader isLoading={true} />}
          onReady={() => setTextAreaMaxLengths(FORM_TEXTAREA_VALIDATIONS)}
          onFormSubmitted={() => setShowTopSection(false)}
        />
      )}
    </section>
  );
}

import React from "react";
import HubspotForm from "react-hubspot-form";
//import { getEnvironment } from "helpers/utils";
import { HUBSPOT_PORTAL_ID, ENVIRONMENT_HUBSPOT_FORM_IDS } from "helpers/constants";
//import { HubspotFormProductionId, HubspotFormStagingId } from "helpers/enums";

export default function HubSpotForm({
  portalId,
  formName,
  onSubmit = () => {},
  onReady = () => {},
  onLoading = {},
  ...props
}) {

  const formId = ENVIRONMENT_HUBSPOT_FORM_IDS[formName];
  // const location = getEnvironment();
  // const formId = React.useMemo(() => {
  //   return ENVIRONMENT_HUBSPOT_FORM_IDS[formName];

  //   // if (location === PRODUCTION_ENVIRONMENT) {
  //   //   return HubspotFormProductionId[formName];
  //   // } else {
  //   //   return HubspotFormStagingId[formName];
  //   // }
  // }, [formName, location]);

  return (
    <HubspotForm
      formId={formId}
      onReady={onReady}
      onSubmit={onSubmit}
      loading={onLoading}
      portalId={HUBSPOT_PORTAL_ID}
      {...props}
    />
  );
}

import React from "react";

function useStickActionItemsTop(ref, setSticky) {
  React.useEffect(() => {
    function stickActionItemsTop() {
      if (window.scrollY > ref?.current?.offsetTop) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    }
    window.addEventListener("scroll", stickActionItemsTop);
    return () => window.removeEventListener("scroll", stickActionItemsTop);
  }, [ref, setSticky]);
}

export default useStickActionItemsTop;

import React from "react";
import classNames from "classnames";
import SponsorImage from "./sponsorImage";
import { getUrlForHref } from "helpers/utils";

export default function SponsorCard({ dataObj, cardTitle, className }) {
  return (
    <div className="sponsors_wrapper">
      <h2 className={classNames("sponsors_wrapper_title", className)}>
        <span>{cardTitle}</span>
      </h2>
      <div className="sponsors_list">
        <ul className={classNames(`list-inline ${className}`)}>
          {dataObj?.map((sponsorData, key) => (
            <React.Fragment key={key}>
              <li key={key}>
                <div
                  className={classNames("sponsors_card", {
                    hasBrokenImg: !sponsorData.logoLocation
                  })}>
                  <a
                    rel="noreferrer"
                    title={sponsorData.organizationName}
                    href={getUrlForHref(sponsorData.organizationUrl)}
                    target={!sponsorData.organizationUrl ? "" : "_blank"}>
                    <SponsorImage imgData={sponsorData} />
                  </a>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}

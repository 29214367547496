import React from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import classNames from "classnames";

function getClassNames(className, variant, size, theme = "reg") {
  const computedClassNames = classNames(className, {
    rcToolTip: true,
    [`theme--${theme}`]: theme,
    [`rcToolTip--${variant}`]: variant,
    [`rcToolTip--${size}`]: size
  });
  return computedClassNames;
}

export const ToolTip = React.memo(({ className, variant, size, theme, ...props }) => {
  const computedClassNames = React.useMemo(
    () => getClassNames(className, variant, size, theme),
    [className, variant, size, theme]
  );

  return (
    <Tooltip
      trigger={props.trigger}
      overlay={props.content}
      placement={props.placement}
      overlayClassName={computedClassNames}>
      {props.children}
    </Tooltip>
  );
});

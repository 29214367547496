import classNames from "classnames";
import React from "react";

export const ImageOrVideoContent = ({ overlayContent, content, imgSrc, videoSrc, className }) => {
  return (
    <section className={classNames("global_section imgOrVideoSection", className)}>
      <div className="container">
        {(imgSrc || videoSrc) && (
          <div className="media_wrapper">
            {imgSrc && <img src={imgSrc} alt="img" />}
            {videoSrc && <video src={videoSrc} loop muted autoPlay playsInline />}
          </div>
        )}
        {overlayContent && (
          <div className="cloud_section">
            <h4 dangerouslySetInnerHTML={{ __html: overlayContent }} />
          </div>
        )}
        {content && (
          <h4
            className="global_section_subtitle global_section_feedback_subtitle text-center"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </section>
  );
};

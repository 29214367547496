import classNames from "classnames";
import React from "react";

export const TitleContentListing = ({
  className,
  title,
  subTitle,
  list,
  imgSrc,
  listHasImages = false
}) => {
  return (
    <section className={classNames("global_section", className)}>
      <div className="container">
        {title && (
          <h2
            className="global_section_title global_section_feedback_title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subTitle.top && (
          <h4
            className="global_section_subtitle global_section_feedback_subtitle text-center"
            dangerouslySetInnerHTML={{ __html: subTitle.top }}
          />
        )}
        {imgSrc && <img src={imgSrc} alt="" />}
        {list && (
          <ul
            className={classNames("feedbackListing list-unstyled", {
              thumbnailSection: listHasImages
            })}>
            {list.map((data, idx) => {
              return (
                <li key={idx}>
                  {listHasImages ? (
                    <ThumbnailWithContent imgSrc={data.imgSrc} content={data.content} />
                  ) : (
                    <CirculerSection title={data.title} content={data.content} />
                  )}
                </li>
              );
            })}
          </ul>
        )}
        {subTitle.bottom && (
          <h4
            className="global_section_subtitle global_section_feedback_subtitle text-center"
            dangerouslySetInnerHTML={{ __html: subTitle.bottom }}
          />
        )}
      </div>
    </section>
  );
};

function ThumbnailWithContent({ imgSrc, content }) {
  return (
    <div className="thumbnailSection_wrapper">
      <img src={imgSrc} alt="img" />
      <p dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

function CirculerSection({ title, content }) {
  return (
    <div className="circleBg">
      <h4 dangerouslySetInnerHTML={{ __html: title }} />
      <h6 dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}

import React from "react";
import sortBy from "lodash/sortBy";
import TicketCard from "./ticketCard";
import { useCopy } from "utility/copyContext";

export default function TicketList({ ticketData }) {
  const { tickets } = useCopy();
  const sortedTickets = sortBy(tickets.ticketList, "displayOrder");
  return (
    <React.Fragment>
      <div className="container">
        <div className="global_section_tickets_list">
          <ul className="list-inline">
            {sortedTickets?.map(data => {
              const ticket = ticketData.find(ticket => ticket.id === data.id);
              const color = ticket ? data.color : "";
              const className = ticket ? data.className : "";
              if (ticket) {
                return (
                  <li key={ticket.id} className={className}>
                    <TicketCard
                      color={color}
                      ticketData={ticket}
                      className={className}
                      addBreakAfterAmpersand={data.addBreakAfterAmpersand}
                    />
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

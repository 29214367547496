import React from "react";
import FM_LINKS from "routes";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useStickActionItemsTop from "hooks/useStickActionItemsTop";

export default function SpeakerTopSection({
  btnText,
  mainTitle,
  viewAgenda,
  flipContent,
  viewAgendaUrl,
  speakerDescription
}) {
  const ref = React.useRef();
  const [sticky, setSticky] = React.useState(false);

  useStickActionItemsTop(ref, setSticky);
  return (
    <div className="speakers_topSection">
      <h1 className="pageTitle text-center">{mainTitle}</h1>
      <div
        className={classNames("speakers_topSection_content", {
          flipSponsorContent: flipContent
        })}>
        <div className="speakers_topSection_content_left">
          {speakerDescription.map((desc, idx) => {
            return <p key={idx} dangerouslySetInnerHTML={{ __html: desc }} />;
          })}
        </div>
        <div
          ref={ref}
          className={classNames("speakers_topSection_content_right", { isSticky: sticky })}>
          <Link className="global_btn" to={FM_LINKS.APPLY_TO_SPEAK_FORM} title={btnText}>
            {btnText}
          </Link>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { useCopy } from "utility/copyContext";

export default function EventsQone(){
    const { home } = useCopy();
    return(
        <section  className="global_section global_section_eventsQone" >
            <div className="container">
                <div className="global_section_eventsQone_img">
                <picture>
                    <source
                    media="(min-width: 768px)"
                    srcSet={home.EventsQoneImg.EventsQoneImgDesktop}></source>
                    <source
                    media="(min-width: 320px)"
                    srcSet={home.EventsQoneImg.EventsQoneImgMobile}></source>
                    <img src={home.EventsQoneImg.EventsQoneImgDesktop} alt="img" />
                </picture>
                </div>
            </div>
        </section>
    );
}
import classNames from "classnames";
import FullPageLoader from "components/FullPageLoader";
import HubSpotForm from "components/HubSpotForm";
import { HUBSPOT_PORTAL_ID, SPEAKER_FORM } from "helpers/constants";
import { isIEBrowser } from "helpers/utils";
import React from "react";
import { useCopy } from "utility/copyContext";

export default function SpeakerForm() {
  const { speakerForm } = useCopy();

  window.jQuery = // jugaad for hubspot, as hubspot `onFormSubmitted` requries jquery
    window.jQuery ||
    (() => ({
      // these are all methods required by HubSpot
      change: () => {},
      trigger: () => {},
      serializeArray: () => {}
    }));

  return (
    <section className={classNames("speakerForm", { "speakerForm--onIe": isIEBrowser() })}>
      <div className="speakerForm_topSection">
        <h1>{speakerForm.title}</h1>
        <p>{speakerForm.content.topParagraph}</p>
        <div dangerouslySetInnerHTML={{ __html: speakerForm.content.list }}></div>
      </div>
      <HubSpotForm
        portalId={HUBSPOT_PORTAL_ID}
        formId={SPEAKER_FORM}
        onLoading={<FullPageLoader isLoading={true} />}
        onReady={() => {
          document.getElementsByTagName("textarea")[0].setAttribute("maxlength", 140);
          document.getElementsByTagName("textarea")[1].setAttribute("maxlength", 2000);
          document.getElementsByTagName("textarea")[2].setAttribute("maxlength", 500);
          document.getElementsByTagName("textarea")[3].setAttribute("maxlength", 500);
        }}
        onFormSubmitted={() => {
          document.getElementsByClassName("speakerForm_topSection")[0].classList.add("hide");
        }}
      />
    </section>
  );
}

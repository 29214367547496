import React from "react";
import FM_LINKS from "routes";
import { Link } from "react-router-dom";
import { useCopy } from "../../utility/copyContext";
import { SocialMediaLinks } from "components/Header";
import { CustomLink } from "components/Link";
import { ReactComponent as Logo } from "assets/img/FN_LOGO.svg";

export default function Footer({ setOpenWaitListForm }) {
  const { generalStr, footer, header, privacy } = useCopy();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_container">
          <div className="footer_logo">
            <a href="/" title={generalStr.eventName}>
              <Logo />
            </a>
          </div>
          <div className="footer_links">
            <ul className="list-unstyled">
              <li>
                <Link onClick={scrollToTop} title={footer.cookies} to={FM_LINKS.COOKIES}>
                  {footer.cookies}
                </Link>
              </li>
              <li>
                <Link
                  onClick={scrollToTop}
                  title={footer.exhibitorRulesAndRegulation}
                  to={FM_LINKS.EXHIBITOR_RULES_AND_REGULATIONS}>
                  {footer.exhibitorRulesAndRegulation}
                </Link>
              </li>
              <li>
                <CustomLink href={privacy.externalUrl} target="_blank" title={footer.privacy}>
                  {footer.privacy}
                </CustomLink>
              </li>
              <li>
                <Link
                  onClick={scrollToTop}
                  to={FM_LINKS.TERMS_OF_PARTICIPATION}
                  title={footer.termsOfAttendanceAndParticipation}>
                  {footer.termsOfAttendanceAndParticipation}
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} title={footer.termsOfUse} to={FM_LINKS.TERMS_OF_USE}>
                  {footer.termsOfUse}
                </Link>
              </li>
              <li>
                <Link
                  onClick={scrollToTop}
                  title={footer.termsAndConditions}
                  to={FM_LINKS.TERMS_AND_CONDITIONS}>
                  {footer.termsAndConditions}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_contactDetails">
          <ul className="list-inline">
            <li dangerouslySetInnerHTML={{ __html: footer.eventLLC }}></li>
            <li
              dangerouslySetInnerHTML={{
                __html: `<a href="mailto:${footer.orgEmail}">${footer.orgEmail}</a>`
              }}></li>
            <li dangerouslySetInnerHTML={{ __html: footer.address }}></li>
            { footer.orgContact &&
            <li
              dangerouslySetInnerHTML={{
                __html: `<a href="tel:${footer.orgContact}">${footer.orgContact}</a>`
              }}></li>
            }
          </ul>
          <ul className="list-inline">
            <li>
              <SocialMediaLinks setOpenWaitListForm={setOpenWaitListForm} />
            </li>
            <li className="joinMailList">
              <Link target="_blank" title={header.getTicket} to={FM_LINKS.TICKETS}>
                {header.getTicket}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import FM_LINKS from "routes";
import { useCopy } from "utility/copyContext";
import FullPageLoader from "components/FullPageLoader";
import { useNavigate } from "react-router-dom";
import useSpeakerService from "../hooks/speakerService";
import SpeakerImage from "./speakerImage";

export default function IndividualSpeaker() {
  const navigate = useNavigate();
  const { speakers } = useCopy();
  const service = useSpeakerService();

  return (
    <main className="speakers_container">
      <FullPageLoader isLoading={service.isLoading} />
      <div className="container">
        {service.selectedSpeaker && (
          <div className="speakers_individual_wrapper">
            <h1 className="pageTitle text-center">{speakers.title}</h1>
            <div className="speakers_individual">
              <div className="speakers_individual_img">
                <span>
                  <SpeakerImage imgData={service.selectedSpeaker} />
                </span>
              </div>
              <div className="speakers_individual_description">
                <div className="speakers_individual_details">
                  <strong>{service.selectedSpeaker.speakerName}</strong>
                  <p>{service.selectedSpeaker.speakerTitle}</p>
                  <b>{service.selectedSpeaker.speakerCompany}</b>
                </div>
                <div className="speakers_individual_desc">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: service.selectedSpeaker.speakerDescription
                    }}
                  />
                  <button
                    className="global_btn"
                    title={speakers.seeAllSpeakers}
                    onClick={() => navigate(FM_LINKS.SPEAKERS)}>
                    {speakers.seeAllSpeakers}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {service.selectedSpeaker === undefined && (
          <div className="speakers_notAvailable">
            <b>{speakers.speakerNotAvailable}</b>
            <button
              className="global_btn"
              title={speakers.seeAllSpeakers}
              onClick={() => navigate(FM_LINKS.SPEAKERS)}>
              {speakers.seeAllSpeakers}
            </button>
          </div>
        )}
      </div>
    </main>
  );
}

import { BlockIcon } from "assets/svg";

export default function NoTicketsFound({ noTicketsForCS }) {
  return (
    <div className="global_section_tickets_noTicketsFound">
      <BlockIcon />
      <p className="noTickets">{noTicketsForCS}</p>
    </div>
  );
}

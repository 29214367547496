import React from "react";
import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function TermsOfUse() {
  useWindowScrollTop();
  const { termsOfUse } = useCopy();
  return (
    <React.Fragment>
      <main className="main_termsOfUse">
        <div className="container">
          <h1 className="static_pageTitle text-center">{termsOfUse.title}</h1>
          <div className="static_page_contentHolder">
            {termsOfUse.headerNote.map((data, idx) => (
              <p key={idx}>{data}</p>
            ))}
            <div className="static_page_privacyCont">
              <ul className="list-unstyled">
                {Object.keys(termsOfUse.keypoints).map((point, idx) => (
                  <li key={idx}>
                    <strong>{termsOfUse.keypoints[point].title}</strong>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: termsOfUse.keypoints[point].desc
                      }}></p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

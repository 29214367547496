import React from "react";
import classNames from "classnames";
import { TooltipInfo } from "assets/svg";
import { ToolTip } from "components/Tooltip";
import { CustomLink } from "components/Link";
import { useCopy } from "utility/copyContext";
import useGetTicketUrl from "hooks/getTicketUrl";
import { ParticipantSubRole } from "helpers/enums";
import { getFormattedCurrency,getResolvedString, isMacOS } from "helpers/utils";

export default function TicketCard({ ticketData, color, className, addBreakAfterAmpersand }) {
  const { tickets } = useCopy();
  const ticketUrl = useGetTicketUrl(ticketData);
  const termsAndConditions = React.useMemo(() => {
    return <div dangerouslySetInnerHTML={{ __html: ticketData.termsAndConditions }}></div>;
  }, [ticketData.termsAndConditions]);
  const ticketTitle = React.useMemo(() => {
    if (addBreakAfterAmpersand) {
      return ticketData.title.split("&").join("& <br />");
    }
    return ticketData.title;
  }, [addBreakAfterAmpersand, ticketData.title]);
  return (
    <div className={classNames("ticket_card", className, { "tickets_box--macOs": isMacOS() })}>
      <div className="ticket_card_head" style={{ backgroundColor: color }}>
        <div
          className={classNames("ticket_card_head_content", {
            hasTermsAndConditions: ticketData.termsAndConditions
          })}>
          <strong dangerouslySetInnerHTML={{ __html: ticketTitle }} />
          {ticketData.termsAndConditions && (
            <ToolTip content={termsAndConditions}>
              <span className="ticketsConditionTooltip">
                <TooltipInfo />
              </span>
            </ToolTip>
          )}
        </div>
      </div>
      <div
        className={classNames("ticket_card_body", {
          "ticket_card_body--isApplicationFlow": ticketData.applicationFlow
        })}>
        {ticketData.applicationFlow && ticketData.subRole === ParticipantSubRole.BUYER ? (
          <strong style={{ color: color }}>{tickets.free}</strong>
        ) : ticketData.paymentFlow ? (
          <span>{tickets.registerNowFor}</span>
        ) : null}
        {ticketData.paymentFlow && (
          <div className="ticket_card_priceDetails">
            { ticketData?.applicableRate?.discountPercentage !== 0 && (
              <React.Fragment>
                <strong className="ticket_card_priceDetails_discountedPrice">
                  {getFormattedCurrency(ticketData?.applicableRate?.applicablePrice)}
                </strong>
                <p
                  className="ticket_card_priceDetails_discountPercentage"
                  dangerouslySetInnerHTML={{
                    __html: getResolvedString(tickets.ticketDiscountText, {
                      discount: ticketData?.applicableRate?.discountPercentage
                    })
                  }}
                />
              </React.Fragment>
            )}
            <strong className={classNames("ticket_card_priceDetails_actualPrice", { noDiscount: ticketData?.applicableRate?.discountPercentage === 0 })} >
              {getFormattedCurrency(ticketData?.rateCard?.basePrice)}
            </strong>
          </div>
        )}
        <p
          className="ticket_card_description"
          dangerouslySetInnerHTML={{ __html: ticketData?.ticketsPageBody }}
        />
      </div>
      <div className="ticket_card_footer">
        <CustomLink color={color} target="_blank" href={ticketUrl}>
          {ticketData.paymentFlow ? tickets.getTicket : tickets.applyNow}
        </CustomLink>
      </div>
    </div>
  );
}

import { MAX_LENGTH_140 } from "helpers/constants";

export const FORM_TEXTAREA_FIELDS = {
  DESCRIBE_WHAT_COMPANY_DOES: "describe_what_company_does"
};

export const FORM_TEXTAREA_VALIDATIONS = {
  [FORM_TEXTAREA_FIELDS.DESCRIBE_WHAT_COMPANY_DOES]: {
    maxLength: MAX_LENGTH_140
  }
};

import React from "react";
import Train from "assets/img/regClosed_new.png";
import { useCopy } from "utility/copyContext";
import { getResolvedString } from "helpers/utils";

export default function RegClosed(props) {
  const { generalStr, registrationClosed } = useCopy();
  // TODO: SR stick footer to bottom

  return (
    <section className="registrationClosed">
      <div className="contentWrapper container">
        <div className="content">
          <h1>{registrationClosed.title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: getResolvedString(registrationClosed.regEnded, {
                eventName: generalStr.eventName
              })
            }}></p>
          <div>
            <small></small>
            <span
              dangerouslySetInnerHTML={{
                __html: getResolvedString(registrationClosed.contact, {
                  contactEmail: `<a href="mailto:${generalStr.contactEmail}" title="Email Us">${generalStr.contactEmail}</a>`
                })
              }}></span>
          </div>
        </div>
        <div className="image">
          <img src={Train} alt="Registration Closed" />
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { WhoSection } from "./components/Who";
import { WhySection } from "./components/Why";
import { AboutSection } from "./components/About";
import { AgendaSpeakersSection } from "./components/AgendaSpeakers";
import { homePageCriteoTag, scrollBackToSection, userScrolledToSection } from "helpers/utils";
import { ScrollingContent } from "./components/ScrollingContent";
import { WelcomeFintech } from "./components/WelcomeFintech";
import { IntroVideo } from "./components/IntroVideo";
import EventsQone from "./components/EventsQone";

export default function Home() {
  const [criteoTagsScroll, setCriteoTagsScroll] = React.useState({
    about: false,
    agendaAndSpeakers: false
  });

  React.useEffect(() => {
    homePageCriteoTag();
    scrollBackToSection();
  }, []);

  window.onscroll = () => {
    userScrolledToSection(criteoTagsScroll, setCriteoTagsScroll);
  };

  return (
    <React.Fragment>
      <main className="main_home">
        <IntroVideo />
        <ScrollingContent />
        <WelcomeFintech />
        <AboutSection />
        <EventsQone />
        <WhoSection />
        <WhySection />
        <AgendaSpeakersSection />
      </main>
    </React.Fragment>
  );
}

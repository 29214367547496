import React from "react";
import { DollarIcon } from "assets/svg";

const LoaderType = {
  FLIP: "default",
  CIRCLE: "circleLoader"
};

export default function FullPageLoader({ isLoading, loaderType = LoaderType.CIRCLE }) {
  switch (loaderType) {
    case LoaderType.CIRCLE:
      return isLoading ? <CircleLoader /> : null;
    default:
      return isLoading ? <FlipLoader /> : null;
  }
}

export function FlipLoader() {
  return (
    <div className="loader_overlay">
      <div className="fsmLoader">
        <DollarIcon />
      </div>
    </div>
  );
}
export function CircleLoader() {
  return (
    <div className="loader_overlay">
      <div className="fsmLoader noFlip">
        <DollarIcon />
      </div>
    </div>
  );
}

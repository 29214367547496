import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function HostedTermsAndConditions() {
  useWindowScrollTop();
  const { hostedTermsAndConditions } = useCopy();
  return (
    <main className="main_termsConditions">
      <div className="container">
        <h1
          className="static_pageTitle text-center"
          dangerouslySetInnerHTML={{ __html: hostedTermsAndConditions.title }}></h1>
        <div className="static_page_contentHolder">
          {hostedTermsAndConditions.sectionContent.map((data, idx) => (
            <p key={idx} dangerouslySetInnerHTML={{ __html: data }}></p>
          ))}
          <div className="static_page_conditionsList">
            <ul className="list-unstyled">
              {hostedTermsAndConditions.conditionsList.map((data, idx) => {
                return (
                  <li key={idx}>
                    <p dangerouslySetInnerHTML={{ __html: data.mainList }}></p>
                    <ul className="list-unstyled isSubList">
                      {data.list &&
                        data.list.map(listData => {
                          return listData?.subList?.map((subListData, subListIdx) => {
                            if (subListData !== "") {
                              return (
                                <li key={subListIdx}>
                                  <p dangerouslySetInnerHTML={{ __html: subListData }}></p>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          });
                        })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

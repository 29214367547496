import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import Favicon from "react-favicon";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CopyProvider } from "./utility/copyContext";
import { DEFAULT_FAVICON_URL } from "./helpers/constants";
import { BrowserRouter as Router } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Favicon url={DEFAULT_FAVICON_URL} />
    <CopyProvider>
      <Router>
        <App />
      </Router>
    </CopyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

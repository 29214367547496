import React from "react";
import { useCopy } from "utility/copyContext";
import Marquee from "react-fast-marquee";

export const ScrollingContent = () => {
  const { home } = useCopy();

  return (
    <section className="global_section global_section_scrollingContent" id="scrolling_content">
      <Marquee loop={0} speed={50} pauseOnHover={true}>
        <ul className="list-inline">
          {home.scrollingContent.items.map((item, idx) => {
            return <li key={idx}>{`"${item}"`}</li>;
          })}
        </ul>
      </Marquee>
    </section>
  );
};

export class Base64Util {
  static encode(str) {
    if (!str) return;
    try {
      return btoa(str);
    } catch (e) {
      return;
    }
  }

  static decode(base64Str) {
    if (!base64Str) return;
    try {
      return atob(base64Str);
    } catch (e) {
      return;
    }
  }
}

import React from "react";
import { BrokenImgIcon } from "assets/svg";

export default function SpeakerImage({ imgData }) {
  return (
    <React.Fragment>
      {imgData.speakerImage ? (
        <img alt={imgData.speakerName} src={imgData.speakerImage} />
      ) : (
        <BrokenImgIcon />
      )}
    </React.Fragment>
  );
}

import React from "react";
import { Navigate } from "react-router-dom";
import { ScheduleListIcon } from "assets/svg";
import { useCopy } from "utility/copyContext";

export default function Schedule() {
  const { generalStr, schedule } = useCopy();

  if (!generalStr.showSchedule) return <Navigate to='/' />;

  return (
    <main className="schedule_wrapper">
      <section className="global_section global_section_schedule">
        <div className="container">
          <div className="global_section_schedule_content">
            <div className="global_section_schedule_content_left">
              <h1 className="global_section_title text-left">
                Schedule<span>-at-a-Glance</span>
              </h1>
              <div className="global_section_content">
                <p>{schedule.title}</p>
                {schedule.list && (
                  <ul className="list-unstyled">
                    {schedule.list.map((item, idx) => {
                      return (
                        <li key={idx}>
                          <ScheduleListIcon />
                          <span>{item}</span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            <div className="global_section_schedule_content_right">
              <img alt="Schedule" src={schedule.scheduleContentImg} />
            </div>
          </div>
        </div>
        <div className="global_section_schedule_img">
          <picture>
            <source
              media="(min-width: 768px)"
              title="Schedule-at-a-glance"
              srcSet={schedule.scheduleImgDesktop}></source>
            <source
              media="(min-width: 320px)"
              title="Schedule-at-a-glance"
              srcSet={schedule.scheduleImgMobile}></source>
            <img alt="Schedule-at-a-glance" src={schedule.scheduleImgDesktop} />
          </picture>
        </div>
      </section>
    </main>
  );
}

import React from "react";
import classNames from "classnames";

export const ImageTitleContent = ({ className, contentPlacement, title, description, imgSrc }) => {
  return (
    <section className={classNames("global_section", className)}>
      <div className="container">
        {contentPlacement === "top" && <Content title={title} description={description} />}
        {imgSrc && (
          <div className="global_section_futureOfEvents_img">
            <img src={imgSrc} alt="" />
          </div>
        )}
        {contentPlacement === "bottom" && <Content title={title} description={description} />}
      </div>
    </section>
  );
};

function Content({ title, description }) {
  return (
    <React.Fragment>
      {title && (
        <h2
          className="global_section_title global_section_feedback_title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {description && (
        <h4
          className="global_section_subtitle global_section_feedback_subtitle text-center"
          dangerouslySetInnerHTML={{ __html: description }}></h4>
      )}
    </React.Fragment>
  );
}

import React from "react";
import queryString from "query-string";
import { useCopy } from "utility/copyContext";
import TicketsDefaultView from "./components/ticket";
import RegClosed from "components/RegistrationClosed";
import useWindowScrollTop from "hooks/useWindowScrollTop";
import RegYetToStart from "components/RegistrationYetToStart";
import CommunitySponsorTickets from "./components/communitySponsorTickets";

export default function TicketsPage() {
  useWindowScrollTop();
  const { generalStr } = useCopy();
  const { spId: sponsorOrganizationId } = queryString.parse(window.location.search);
  if (generalStr.regClosed) return <RegClosed />;
  if (generalStr.regYetToStart) return <RegYetToStart />;
  return sponsorOrganizationId ? <CommunitySponsorTickets /> : <TicketsDefaultView />;
}

import { MAX_LENGTH_140, MAX_LENGTH_500, TEXT_AREA_MAX_LENGTH } from "helpers/constants";

export const FORM_TEXTAREA_FIELDS = {
  DESCRIBE_WHAT_COMPANY_DOES: "describe_what_company_does",
  BIOGRAPHY: "biography",
  TOPICS: "topics",
  VIDEOS: "videos"
};

export const FORM_TEXTAREA_VALIDATIONS = {
  [FORM_TEXTAREA_FIELDS.DESCRIBE_WHAT_COMPANY_DOES]: {
    maxLength: MAX_LENGTH_140
  },
  [FORM_TEXTAREA_FIELDS.BIOGRAPHY]: {
    maxLength: TEXT_AREA_MAX_LENGTH
  },
  [FORM_TEXTAREA_FIELDS.TOPICS]: {
    maxLength: MAX_LENGTH_500
  },
  [FORM_TEXTAREA_FIELDS.VIDEOS]: {
    maxLength: MAX_LENGTH_500
  }
};

import { useCopy } from "utility/copyContext";
import useWindowScrollTop from "hooks/useWindowScrollTop";

export default function TermsAndConditions() {
  useWindowScrollTop();
  const { termsAndConditions } = useCopy();
  return (
    <main className="main_termsConditions">
      <div className="container">
        <h1
          className="static_pageTitle text-center"
          dangerouslySetInnerHTML={{ __html: termsAndConditions.title }}></h1>
        <div className="static_page_contentHolder">
          {termsAndConditions.sectionContent.map((data, idx) => (
            <p key={idx} dangerouslySetInnerHTML={{ __html: data }}></p>
          ))}
          <div className="static_page_conditionsList">
            <ul className="list-unstyled">
              {termsAndConditions.conditionsList.map((data, idx) => {
                return (
                  <li key={idx}>
                    <p dangerouslySetInnerHTML={{ __html: data.mainList }}></p>
                    <ul className="list-unstyled isSubList">
                      {data.list.map((listData, listIndex) => {
                        if (listData !== "") {
                          return (
                            <li key={listIndex}>
                              <p dangerouslySetInnerHTML={{ __html: listData.listContent }}></p>
                              <ul className="list-unstyled isSubList hasListStyle">
                                {listData.subList.map((subListData, subListIdx) => {
                                  if (subListData !== "") {
                                    return (
                                      <li key={subListIdx}>
                                        <p dangerouslySetInnerHTML={{ __html: subListData }}></p>
                                      </li>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </ul>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}
